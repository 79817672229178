import './Hero.css';
import '../App.css';
import React from 'react'
import { Button } from './Button'

function Hero() {
    return (
        <div className='hero-container'>
            <h1>Bestel nu je kaartjes!</h1>
            <p>Gepersonaliseerd & op maat gemaakt!</p>

            <div className='btns'>
                <Button className='btns' buttonStyle='btn--outline' buttonSize='btn--large' >BESTEL NU</Button>
                <Button className='btns' buttonStyle='btn--primary' buttonSize='btn--large' >PERSONALISEER JE KAART</Button>
            </div>

        </div>
    )
}

export default Hero